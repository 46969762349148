$theme-colors: ("primary": #7fa25b,
    "danger": #ff4136,
    "info": #7fa25b
    );

//$border-radius:               0 !default;
//$border-radius-lg:            0 !default;
//$border-radius-sm:            0 !default;

@import "node_modules/bootstrap/scss/bootstrap";
@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700";

$fa-font-path: "../fonts";

@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/flatpickr/dist/flatpickr";


body {
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    background-color: #eee;

    background: linear-gradient(to top right, #eee 0%, #fff 100%);
    color: black;
    font-size: 14px;
}


.card{
    box-shadow: 2px 5px 20px rgba(119, 119, 119, 0.5);
}

header {
    border-bottom: 1px solid #eaeaea;
    background-color: #fff;
    padding: 20px 0;

    .navbar-brand {
        padding: 0;
        margin: 0;
        font-size: 36px;
        color: #555;
        font-weight: bold;

        img {
            max-height: 60px;
        }

        @include media-breakpoint-down(xs) {
            font-size: 24px;

            .logo {
                max-height: 40px;
            }

            .secondary-logo {
                max-height: 130px;
                //margin-top: -20px;
            }
        }


    }
}

main {
    padding: 30px 0;
}

footer {
    font-size: 14px;
    color: #999999;
    padding: 30px 0;
    background-color: #262626;
    border-top: 1px solid #3a3a3a;
    border-bottom: 1px solid #333333;
}





h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    line-height: 1.5em
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif
}

blockquote footer:before,
blockquote small:before {
    content: "\2014 \00A0"
}

h1 {
    font-size: 3em;
    line-height: 1.15em
}

h2 {
    font-size: 2.6em
}

h3 {
    font-size: 1.825em;
    margin: 20px 0 10px
}

h3,
h4 {
    line-height: 1.4em
}

h4 {
    font-size: 1.3em;
    margin-top: 10px;
    margin-bottom: 10px
}

h5 {
    font-size: 1.25em;
    line-height: 1.4em;
    margin-bottom: 15px
}

h6 {
    font-size: .8em;
    text-transform: uppercase;
    font-weight: 500
}

h4,
h5,
h6 {
    margin-top: 10px;
    margin-bottom: 10px
}

p {
    margin: 0 0 10px
}



blockquote p {
    font-style: italic
}

